import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Niet gevonden" />
    <h1>NIET GEVONDEN</h1>
    <p>Deze pagina bestaat niet... wat jammer :(</p>
  </Layout>
)

export default NotFoundPage
